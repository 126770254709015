import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ButtonProgressComponent, ButtonProgressState } from '@mri-platform/ui-buttons';
import { ButtonIconComponent } from '@mri-platform/ui-icon';

@Component({
  selector: 'mri-master-detail-footer-toolbar',
  standalone: true,
  template: `
    <div class="mri-toolbar__group">
      @if (showDelete) {
        <button
          data-testid="mri-master-detail-footer-toolbar-delete-button"
          class="mri-button"
          [mriButtonProgress]="deleteProgressState"
          (resetProgress)="deleteResetProgress.emit(true)"
          [disabled]="!canDelete"
          (click)="delete.emit()"
        >
          <mri-btn-icon icon="trash-can"></mri-btn-icon>
          {{ deleteLabel }}
        </button>
      }
      <button
        data-testid="mri-master-detail-footer-toolbar-save-button"
        class="mri-button mri-button--primary"
        [mriButtonProgress]="saveProgressState"
        (resetProgress)="saveResetProgress.emit(true)"
        [disabled]="!canSave"
        [title]="saveTooltip"
        (click)="save.emit()"
      >
        <mri-btn-icon icon="save"></mri-btn-icon>
        Save
      </button>
      <button
        data-testid="mri-master-detail-footer-toolbar-cancel-button"
        class="mri-button"
        [disabled]="!canCancel"
        (click)="cancel.emit()"
      >
        <mri-btn-icon icon="x"></mri-btn-icon>
        Cancel
      </button>
    </div>
  `,
  imports: [ButtonProgressComponent, ButtonIconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasterDetailFooterToolbarComponent {
  @Input() canCancel = false;
  @Input() canDelete = false;
  @Input() canSave = false;
  @Input() saveTooltip = '';
  @Input() deleteLabel = 'Delete';
  @Input() deleteProgressState?: ButtonProgressState;
  @Input() saveProgressState?: ButtonProgressState;
  @Input() showDelete = false;
  @Output() cancel = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() deleteResetProgress = new EventEmitter<true>();
  @Output() save = new EventEmitter<void>();
  @Output() saveResetProgress = new EventEmitter<true>();
  @HostBinding('class.mri-toolbar')
  @HostBinding('class.mri-master-detail__footer-toolbar')
  hostClass = true;
}
