import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'mri-master-detail-header-title, [mriMasterDetailHeaderTitle]',
  standalone: true,
  template: '<ng-content></ng-content>',
  styles: [
    `
      :host {
        display: inline;
      }
    `
  ]
})
export class MasterDetailHeaderTitleComponent {
  @HostBinding('class.mri-heading__text') readonly hostClass = true;
}
