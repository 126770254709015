import { EntityName } from '@mri-platform/import-export/core';
import { AuthzContextsActionMap } from '@mri-platform/resource-authz';
import { createModelMetadata } from '@mri-platform/shared/core';
import { NEW_ENTITY_ID } from '@mri-platform/shared/entity';
import uniqBy from 'lodash-es/uniqBy';

export interface RefreshApiHistory {
  id: number;
  platformId: number;
  clientId?: number;
  dataSourceId?: number;
  active?: boolean;
  status?: RefreshApiStatus;
  remarks?: string;
  startDatetime?: string;
  endDatetime?: string;
}

export enum RefreshApiStatus {
  Draft = 'Draft',
  InProgress = 'InProgress',
  Refreshed = 'Refreshed',
  Failed = 'Failed'
}

export const RefreshApiHistory = createModelMetadata<RefreshApiHistory>({
  entityName: EntityName.RefreshApiHistory,
  authorization: AuthzContextsActionMap.crudFor(EntityName.RefreshApiHistory),
  sortComparer: (a, b) => (b.id > a.id ? 1 : -1)
});

export const isRefreshStarted = (h: RefreshApiHistory) =>
  h.status === RefreshApiStatus.Draft || h.status === RefreshApiStatus.InProgress;

export const isRefreshCompleted = (h: RefreshApiHistory) => h.status === RefreshApiStatus.Refreshed;

export const isRefreshFailed = (h: RefreshApiHistory) => h.status === RefreshApiStatus.Failed;

export function getCurrentRefreshApiHistoryForPlatformOrDataSource(
  list: RefreshApiHistory[],
  platformId: number,
  datasourceId?: number
) {
  if (list.length) {
    const updateRefreshHistories = datasourceId ? uniqBy(list, 'dataSourceId') : uniqBy(list, 'platformId');
    if (datasourceId) {
      const refreshHistory = updateRefreshHistories.find(ra => ra.dataSourceId === datasourceId);
      return refreshHistory ? refreshHistory : createRefreshApiHistory(platformId, datasourceId);
    }
    return updateRefreshHistories[0];
  }
  return createRefreshApiHistory(platformId);
}

export function createRefreshApiHistory(platformId: number, dataSourceId?: number): RefreshApiHistory {
  return {
    id: NEW_ENTITY_ID,
    platformId,
    dataSourceId
  };
}
