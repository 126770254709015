import { Component, HostBinding, Input, TemplateRef } from '@angular/core';
import { MasterDetailHeaderTitleComponent } from './master-detail-header-title.component';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'mri-master-detail-header',
  standalone: true,
  template: `
    <ng-content selector="mri-avatar"></ng-content>
    <h3 class="mri-heading mri-heading--3 mri-master-detail__detail-title">
      @if (!title) {
        <ng-content select="mri-master-detail-header-title, [mriMasterDetailHeaderTitle]"></ng-content>
      } @else {
        <mri-master-detail-header-title>{{ title }}</mri-master-detail-header-title>
      }
    </h3>
    <ng-container *ngTemplateOutlet="toolbarTemplate"></ng-container>
  `,
  imports: [MasterDetailHeaderTitleComponent, NgTemplateOutlet],
  styleUrl: 'master-detail-header.component.scss'
})
export class MasterDetailHeaderComponent {
  @HostBinding('class.mri-master-detail__detail-header') readonly hostClass = true;
  @HostBinding('class.no-background-color')
  @Input()
  noBackgroundColor = false;
  @Input() title = '';
  toolbarTemplate!: TemplateRef<never>;
}
