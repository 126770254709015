import { Component, HostBinding } from '@angular/core';
import { ButtonIconComponent } from '@mri-platform/ui-icon';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[mriCloseButton]',
  standalone: true,
  imports: [ButtonIconComponent],
  template: '<mri-btn-icon icon="x"></mri-btn-icon>Close'
})
export class CloseButtonComponent {
  @HostBinding('class.mri-button')
  @HostBinding('class.mri-button--primary')
  readonly hostClass = true;
}
