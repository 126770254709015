/* eslint-disable @typescript-eslint/no-explicit-any */
// NOTE: angular `ErrorHandler` uses `any` in it's public api, therefore we're following the same pattern here

import { ErrorHandler, Injectable } from '@angular/core';
import { firstValueFrom, of, throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ErrorMessageTranslationService } from './error-message-translation.service';
import { ErrorSanitizerFactoryService } from './error-sanitizer-factory.service';
import {
  ErrorHandlerOptions,
  catchHandleError,
  catchHandleReplaceError,
  catchHandleRethrowError,
  catchHandleSwallowError,
  handleError
} from './operators';
import { SanitizedError, SanitizedErrorData } from './sanitized-error';

export type OptionsFactoryFunction = (defaultOptions: Readonly<ErrorHandlerOptions>) => Partial<ErrorHandlerOptions>;

export function forceError(defaultOptions: Readonly<ErrorHandlerOptions>): Partial<ErrorHandlerOptions> {
  return {
    sanitizer: (err: any) => {
      const sanitizedErr = defaultOptions.sanitizer(err);
      return sanitizedErr !== undefined ? sanitizedErr.asError() : undefined;
    }
  };
}

export function muteError(defaultOptions: Readonly<ErrorHandlerOptions>): Partial<ErrorHandlerOptions> {
  return {
    sanitizer: (err: any) => {
      const sanitizedErr = defaultOptions.sanitizer(err) ?? SanitizedError.genericError(err);
      return sanitizedErr.asMuted();
    }
  };
}

export function forceMutedError(defaultOptions: Readonly<ErrorHandlerOptions>): Partial<ErrorHandlerOptions> {
  return {
    sanitizer: (err: any) => {
      const sanitizedErr = defaultOptions.sanitizer(err) ?? SanitizedError.genericError(err);
      return sanitizedErr.asError().asMuted();
    }
  };
}

export function showAlways(defaultOptions: Readonly<ErrorHandlerOptions>): Partial<ErrorHandlerOptions> {
  return {
    sanitizer: (err: any) => {
      const sanitizedErr = defaultOptions.sanitizer(err) ?? SanitizedError.genericError(err);
      return sanitizedErr.asUnmuted();
    }
  };
}

export function sanitizePostAsFetch(defaultOptions: Readonly<ErrorHandlerOptions>): Partial<ErrorHandlerOptions> {
  return {
    sanitizer: (err: unknown) => {
      const sanitizedErr = defaultOptions.sanitizer(err) ?? SanitizedError.genericError(err);
      if (sanitizedErr.detail?.status === 400) {
        // even though this is a POST and that's synonymous with saving a resource, we do NOT want to show
        // the generic save message
        const message =
          sanitizedErr.message === SanitizedError.saveMessage ? SanitizedError.fetchMessage : sanitizedErr.message;

        // usually a 400 (BadRequest) from a POST is NOT considered an error but a usual validation exception
        // that is neither logged or shown as a red toast.
        // a 400 from a fetch WOULD be considered an error therefore treat is so here.
        // that way we make sure to show and log it as an error
        return sanitizedErr.as({ message, isError: true });
      }
      return sanitizedErr;
    }
  };
}

export function sanitizeWith(error: Partial<SanitizedErrorData>): OptionsFactoryFunction {
  return (defaultOptions: Readonly<ErrorHandlerOptions>) => ({
    sanitizer: (err: unknown) => defaultOptions.sanitizer(err)?.as(error)
  });
}

const noOverrides: Partial<ErrorHandlerOptions> = {};
export function useDefaults(): Partial<ErrorHandlerOptions> {
  return noOverrides;
}

@Injectable({ providedIn: 'root' })
export class ErrorPolicyService {
  private readonly defaultOptions: Readonly<ErrorHandlerOptions>;

  constructor(
    private globalHandler: ErrorHandler,
    errorTranslationService: ErrorMessageTranslationService,
    errorSanitizerFactoryService: ErrorSanitizerFactoryService
  ) {
    this.defaultOptions = {
      translator: errorTranslationService.translate.bind(errorTranslationService),
      sanitizer: errorSanitizerFactoryService.getSanitizer(),
      notify: true
    };
    Object.freeze(this.defaultOptions);

    // make it convenient to pass member functions as pointers
    this.log = this.log.bind(this);
    this.logAndRethrow = this.logAndRethrow.bind(this);
  }

  catchHandle<T>(optionsFactory?: OptionsFactoryFunction) {
    return catchHandleError<T>(this.globalHandler, this.getOptions(optionsFactory));
  }

  catchHandleRethrow<T>(optionsFactory?: OptionsFactoryFunction) {
    return catchHandleRethrowError<T>(this.globalHandler, this.getOptions(optionsFactory));
  }

  catchHandleReplace<T, R = T>(fallback: R, optionsFactory?: OptionsFactoryFunction) {
    return catchHandleReplaceError<T, R>(this.globalHandler, fallback, this.getOptions(optionsFactory));
  }

  catchHandleSwallow<T>(optionsFactory?: OptionsFactoryFunction) {
    return catchHandleSwallowError<T>(this.globalHandler, this.getOptions(optionsFactory));
  }

  handle(optionsFactory?: OptionsFactoryFunction) {
    return handleError(this.globalHandler, this.getOptions(optionsFactory));
  }

  log(err: unknown, optionsFactory?: OptionsFactoryFunction) {
    return firstValueFrom(of(err).pipe(this.handle(optionsFactory)));
  }

  logAndRethrow(err: unknown, optionsFactory?: OptionsFactoryFunction) {
    return firstValueFrom(
      of(err).pipe(
        this.handle(optionsFactory),
        mergeMap(sanitizedErr => throwError(sanitizedErr))
      )
    );
  }

  private getOptions(optionsFactory?: OptionsFactoryFunction): ErrorHandlerOptions {
    if (!optionsFactory) {
      return this.defaultOptions;
    }

    const overrides = optionsFactory(this.defaultOptions);
    return { ...this.defaultOptions, ...overrides };
  }
}
