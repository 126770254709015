import { Component, HostBinding } from '@angular/core';

/**
 * Section within a detail panel of a master-detail page layout.
 *
 * @description Adds a container element with gutters and any other
 * styling appropriate for a section.
 * See also `MasterDetailSectionDirective` if you already have a container element
 * that you want to add section styles to
 */
@Component({
  selector: 'mri-master-detail-section',
  standalone: true,
  template: '<ng-content></ng-content>',
  styles: [':host { display: block; } ']
})
export class MasterDetailSectionComponent {
  @HostBinding('class.mri-mh-large') readonly hostClass = true;
}
