@if (enabled) {
  <form class="k-form k-form-horizontal" [formGroup]="form">
    <div class="row">
      <div class="col-lg-12">
        <kendo-formfield class="">
          @if (!labelText) {
            <kendo-label class="text-right" [for]="connection" text="Selected connection"></kendo-label>
          }
          @if (labelText) {
            <kendo-label class="text-right" [for]="connection" text="{{ labelText }}"></kendo-label>
          }
          @if (displayParent) {
            <kendo-dropdownlist
              #parentConnection
              [data]="apiPrefixes"
              [loading]="loading"
              formControlName="parent"
              [title]="parentConnection?.text ? parentConnection.text : ''"
              class="k-required"
              [readonly]="list.length === 1"
              [ngClass]="{ 'k-readonly': list.length === 1 }"
              [virtual]="{
                itemHeight: 28
              }"
            >
            </kendo-dropdownlist>
          }
          @if (!displayParent) {
            <kendo-dropdownlist
              #connection
              [data]="apiConnectionList"
              [loading]="loading"
              formControlName="connection"
              textField="tableName"
              valueField="id"
              [title]="form.get('connection').value?.description || ''"
              [class.k-required]="true"
              [readonly]="list.length === 1"
              [ngClass]="{ 'k-readonly': list.length === 1, 'mri-k-show-validation': list.length !== 1 }"
              [popupSettings]="{
                width: 'max-content'
              }"
              [virtual]="{
                itemHeight: 28
              }"
            >
              <ng-template kendoDropDownListItemTemplate let-dataItem>
                <span [title]="dataItem.description || ''">{{ dataItem.tableName }}</span>
              </ng-template>
            </kendo-dropdownlist>
          }
          @if (!displayParent && showDownloadBtn && apiConnectionList?.length > 0 && showDownLoadButton) {
            <button
              kendoButton
              look="flat"
              class="mri-mt-small"
              [disabled]="!form.get('connection').value?.id"
              (click)="downloadBtnClicked(form.get('connection').value)"
            >
              <mri-btn-icon icon="download"></mri-btn-icon>
              Download connection field list
            </button>
          }
        </kendo-formfield>
      </div>
    </div>
    @if (displayParent) {
      <div class="row">
        <div class="col-lg-12">
          @if (apiConnectionList?.length > 0) {
            <kendo-formfield>
              <kendo-label class="text-right"></kendo-label>
              <kendo-dropdownlist
                #connection
                [data]="apiConnectionList"
                [loading]="loading"
                formControlName="connection"
                textField="displayName"
                valueField="id"
                [title]="
                  form.get('connection').value
                    ? form.get('connection').value?.verb + ' ' + form.get('connection').value?.displayName
                    : ''
                "
                [class.k-required]="true"
                [readonly]="list.length === 1"
                [ngClass]="{ 'k-readonly': list.length === 1 }"
                [popupSettings]="{
                  width: 'max-content'
                }"
                class="mri-k-show-validation"
                [virtual]="{
                  itemHeight: 28
                }"
              >
                <ng-template kendoDropDownListItemTemplate let-dataItem>
                  <span [title]="dataItem.verb + ' ' + dataItem.displayName">{{ dataItem.displayName }}</span>
                </ng-template>
              </kendo-dropdownlist>
              @if (showDownloadBtn && apiConnectionList?.length > 0 && showDownLoadButton) {
                <button
                  kendoButton
                  look="flat"
                  class="mri-mt-small"
                  [disabled]="!form.get('connection').value?.id"
                  (click)="downloadBtnClicked(form.get('connection').value)"
                >
                  <mri-btn-icon icon="download"></mri-btn-icon>
                  Download connection field list
                </button>
              }
            </kendo-formfield>
          }
        </div>
      </div>
    }
  </form>
}
