import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
@Component({
  selector: 'mri-master-detail-header-toolbar',
  standalone: true,
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasterDetailHeaderToolbarComponent {
  @HostBinding('class.mri-toolbar')
  @HostBinding('class.mri-master-detail__detail-toolbar')
  hostClass = true;
}
