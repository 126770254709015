@if (vm$ | async; as vm) {
  <form class="k-form k-form-horizontal" [formGroup]="form">
    <kendo-formfield>
      <kendo-label [for]="database" text="Database"> </kendo-label>
      <kendo-dropdownlist
        class="mri-k-show-validation"
        #database
        formControlName="id"
        [data]="vm.list.items"
        textField="name"
        valueField="id"
        [valuePrimitive]="true"
      >
      </kendo-dropdownlist>
    </kendo-formfield>
  </form>
}
