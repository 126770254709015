import { Directive, HostBinding } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mri-toolbar-group',
  standalone: true
})
export class ToolbarGroupDirective {
  @HostBinding('class.mri-toolbar__group') readonly hostClass = true;
}
