@if (vm$ | async; as vm) {
  <mri-shared-loading [show]="vm.isLoading"></mri-shared-loading>
  <form [formGroup]="form" class="k-form k-form--inline ms-2">
    <!-- will enable it in language translation implementation -->
    <!-- <div class="row">
    <kendo-formfield class="col-lg-6">
      <kendo-label class="text-left" [for]="language" text="Language"></kendo-label>
      <kendo-dropdownlist
        #language
        mriSharedKendoRequired
        [data]="vm.textLocaleOptions"
        textField="text"
        valueField="value"
        [valuePrimitive]="true"
      ></kendo-dropdownlist>
      <div class="preference-description">Language used when displaying text.</div>
    </kendo-formfield>
  </div> -->
    <div class="row">
      <kendo-formfield class="col-lg-6">
        <kendo-label class="text-left" [for]="numericLocales" text="Numbers"></kendo-label>
        <kendo-dropdownlist
          #numericLocales
          mriSharedKendoRequired
          formControlName="numericLocale"
          [data]="vm.numericLocaleOptions"
          textField="text"
          valueField="value"
          [valuePrimitive]="true"
        ></kendo-dropdownlist>
        <div class="preference-description">Locale used when displaying numbers.</div>
      </kendo-formfield>
      <div class="col-lg-6 example">Example: {{ 1234567.89 | localizeNumber: {} : model }}</div>
    </div>
    <div class="row">
      <kendo-formfield class="col-lg-6">
        <kendo-label class="text-left" [for]="dateLocale" text="Dates"></kendo-label>
        <kendo-dropdownlist
          #dateLocale
          mriSharedKendoRequired
          formControlName="dateLocale"
          [data]="vm.dateLocaleOptions"
          textField="text"
          valueField="value"
          [valuePrimitive]="true"
        ></kendo-dropdownlist>
        <div class="preference-description">Locale used when displaying dates.</div>
      </kendo-formfield>
      <div class="col-lg-6 example">
        Example: {{ today | localizeDateTime: {} : model }};
        {{
          today
            | localizeDateTime
              : {
                  weekday: 'long',
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric'
                }
              : model
        }}
      </div>
    </div>
    <div class="row">
      <kendo-formfield class="col-lg-6">
        <kendo-label class="text-left" [for]="timeFormat" text="Time Format"></kendo-label>
        <kendo-dropdownlist
          #timeFormat
          mriSharedKendoRequired
          formControlName="timeFormat"
          [data]="vm.timeFormatOptions"
          textField="text"
          valueField="value"
          [valuePrimitive]="true"
        ></kendo-dropdownlist>
        <div class="preference-description">Time format used when displaying times.</div>
      </kendo-formfield>
      <div class="col-lg-6 example">Example: {{ today | localizeDateTime: { timeStyle: 'short' } : model }}</div>
    </div>
    <div class="row">
      <kendo-formfield class="col-lg-6">
        <kendo-label class="text-left" [for]="timeOffset" text="Time Offset"></kendo-label>
        <kendo-dropdownlist
          #timeOffset
          mriSharedKendoRequired
          formControlName="timeOffset"
          [data]="vm.timeOffsetOptions"
          textField="text"
          valueField="value"
          [valuePrimitive]="true"
        ></kendo-dropdownlist>
        <div class="preference-description">Offset used when displaying time.</div>
      </kendo-formfield>
    </div>
    <!-- currently we don't have phone numbers -->
    <!-- <div class="row">
<kendo-formfield class="col-lg-6">
  <kendo-label class="text-left" [for]="phoneFormat" text="Phone Format"></kendo-label>
  <kendo-dropdownlist
    #phoneFormat
    mriSharedKendoRequired
    [data]="vm.phoneFormatOptions"
    textField="text"
    valueField="value"
    [valuePrimitive]="true"
  ></kendo-dropdownlist>
  <div class="preference-description">Display phone numbers in standard NANP format.</div>
</kendo-formfield>
<div class="col-lg-6 example">Example: (###) ###-####</div>
</div> -->
  </form>
  <kendo-dialog-actions layout="end" class="pt-0">
    <button kendoButton [disabled]="!vm.isValid || !vm.isDirty" (click)="save()" [primary]="true">Save</button>
    <button kendoButton (click)="close()">Cancel</button>
  </kendo-dialog-actions>
}
