@if (vm$ | async; as vm) {
  <form [formGroup]="form" class="k-form k-form-horizontal">
    <!-- File Picker -->
    <kendo-formfield>
      <kendo-label for="name" text="{{ title }} file" class="text-right mri-ml-auto"></kendo-label>
      <input #name formControlName="name" title="{{ form.controls.name.value }}" kendoTextBox type="text" readonly />
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label for="fileUploaded" class="text-right"></kendo-label>
      <kendo-upload
        #file
        formControlName="fileUploaded"
        class="mri-button--quiet mri-pl-none mri-pb-none mri-k-show-validation"
        [disabled]="!filePickerEnableStatus"
        [saveUrl]="api"
        saveField="file"
        [restrictions]="fileRestrictions"
        [autoUpload]="false"
        [multiple]="false"
        [saveHeaders]="uploadHeaders"
        (select)="toggleFileSelection(true)"
        (remove)="toggleFileSelection(false)"
        (clear)="fileCleared()"
        (success)="uploadSuccess($event)"
        (error)="uploadFailure($event)"
      >
        <kendo-upload-messages
          select="{{ form.controls.name.value ? 'Change File' : 'Browse' }}"
          headerStatusUploaded=""
          dropFilesHere=""
        ></kendo-upload-messages>
      </kendo-upload>
      <kendo-formhint class="mri-pl-small"> {{ fileRestrictions | fileRestrictionsMessage }}</kendo-formhint>
    </kendo-formfield>
    @if (vm.showWorksheetDropdown) {
      <kendo-formfield class="vm.showWorksheetDropdown">
        <kendo-label class="text-right" [for]="sheetName" text="Worksheet"></kendo-label>
        <kendo-dropdownlist
          #sheetName
          mriSharedKendoRequired
          [data]="vm.workSheets"
          formControlName="sheetName"
          class="mri-k-show-validation"
        >
        </kendo-dropdownlist>
      </kendo-formfield>
    }
  </form>
}
