import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreUiModule } from '@mri-platform/shared/core-ui';
import { UI_BUTTONS } from '@mri-platform/ui-buttons';
import { UI_ICONS } from '@mri-platform/ui-icon';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ColorPickerModule, FormFieldModule, InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { UploadModule } from '@progress/kendo-angular-upload';
import { AvatarIconSelectorFormComponent } from './components/avatar-icon-selector-form/avatar-icon-selector-form.component';
import { AvatarIconSelectorPopupComponent } from './components/avatar-icon-selector-popup/avatar-icon-selector-popup.component';
import { EditableColumnSelectAllDirective } from './components/editiable-column-select-all.directive';
import { KendoRequiredDirective } from './components/kendo-required/kendo-required.directive';
import { FileRestrictionsMessagePipe } from './pipes/file-restrictions-message.pipe';
import { ParseDatePipe } from './pipes/parse-date.pipe';
import { ParseTimePipe } from './pipes/parse-time.pipe';

const PUBLIC_DECLARATIONS = [
  AvatarIconSelectorFormComponent,
  AvatarIconSelectorPopupComponent,
  ParseDatePipe,
  ParseTimePipe,
  KendoRequiredDirective,
  FileRestrictionsMessagePipe,
  EditableColumnSelectAllDirective
];
const KENDO_IMPORTS = [
  ButtonModule,
  ColorPickerModule,
  DialogsModule,
  InputsModule,
  FormFieldModule,
  LabelModule,
  UploadModule
];

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, KENDO_IMPORTS, ...UI_BUTTONS, ...UI_ICONS],
  exports: [PUBLIC_DECLARATIONS, CoreUiModule],
  declarations: PUBLIC_DECLARATIONS,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedCommonUiModule {}
