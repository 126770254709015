import { Directive, HostBinding, Optional } from '@angular/core';
import { MasterDetailHeaderComponent } from './master-detail-header.component';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'mri-avatar', standalone: true })
export class MasterDetailHeaderIconDirective {
  @HostBinding('class.mri-master-detail__detail-icon') get hostClass() {
    return this.header != null;
  }
  constructor(@Optional() private header: MasterDetailHeaderComponent) {}
}
