@if (vm$ | async; as vm) {
  <form class="k-form k-form-horizontal" [formGroup]="form">
    @if (vm.clients.length) {
      <div class="row">
        <div class="col-lg-12">
          <kendo-formfield>
            <kendo-label class="text-right" [for]="clientId" text="Client ID"></kendo-label>
            <kendo-dropdownlist
              #clientId
              [data]="vm.clients"
              textField="clientName"
              valueField="clientId"
              formControlName="clientId"
              [valuePrimitive]="true"
              class="k-required mri-k-show-validation"
              [readonly]="vm.clients.length === 1"
              [ngClass]="{ 'k-readonly': vm.clients.length === 1 }"
              [title]="vm.clients | showTitle: 'clientId' : 'clientName' : form.get('clientId').value"
            >
            </kendo-dropdownlist>
          </kendo-formfield>
        </div>
      </div>
    }
    @if (vm.users.length) {
      <div>
        <kendo-formfield>
          <kendo-label class="text-right" [for]="userName" text="Username"></kendo-label>
          <kendo-dropdownlist
            #userName
            [data]="vm.users"
            textField="userName"
            valueField="userName"
            formControlName="userName"
            [valuePrimitive]="true"
            [title]="userName.text"
            class="k-required mri-k-show-validation"
            [readonly]="vm.users.length === 1"
            [ngClass]="{ 'k-readonly': vm.users.length === 1 }"
          >
          </kendo-dropdownlist>
        </kendo-formfield>
      </div>
    }
    @if (vm.databases.length) {
      <div>
        <kendo-formfield>
          <kendo-label class="text-right" [for]="database" text="Database"></kendo-label>
          <kendo-dropdownlist
            #database
            [data]="vm.databases"
            textField="databaseName"
            valueField="databaseId"
            formControlName="databaseId"
            [valuePrimitive]="true"
            [class.k-required]="true"
            [readonly]="vm.databases.length === 1"
            [ngClass]="{ 'k-readonly': vm.databases.length === 1, 'mri-k-show-validation': vm.databases.length !== 1 }"
            [title]="vm.databases | showTitle: 'databaseId' : 'databaseName' : form.get('databaseId').value"
          >
          </kendo-dropdownlist>
        </kendo-formfield>
      </div>
    }
  </form>
}
