import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularResourceAuthzModule } from '@mri-platform/angular-resource-authz';
import { SharedCommonUiModule } from '@mri-platform/shared/common-ui';
import { UI_ICONS } from '@mri-platform/ui-icon';
import { UI_MASTER_DETAIL } from '@mri-platform/ui-master-detail';
import { ElementsCommonModule } from '@mri/ng-elements-common';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule, StepperModule } from '@progress/kendo-angular-layout';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { AccessDetailComponent } from './components/access-detail/access-detail.component';
import { AccessJobConfigurationComponent } from './components/access-token-configuration/access-token-configuration.component';
import { AccessTokenComponent } from './components/access-token/access-token.component';
import { AuditLogsDrawerComponent } from './components/audit-log-drawer/audit-log-drawer.component';
import { ConnectionConfigurationComponent } from './components/connection-configuration/connection-configuration.component';
import { ConnectionFilePickerComponent } from './components/connection-file-picker/connection-file-picker.component';
import { ConnectionComponent } from './components/connection/connection.component';
import { DataSourceSelectionComponent } from './components/data-source-selection/data-source-selection.component';
import { DownloadJobLogsComponent } from './components/download-job-logs/download-job-logs.component';
import { EnrollmentDialogComponent } from './components/enrollment/enrollment-dialog.component';
import { FilterBarItemComponent } from './components/filter-bar-item/filter-bar-item.component';
import { FilterBarComponent } from './components/filter-bar/filter-bar.component';
import { NavCardComponent } from './components/nav-card/nav-card.component';
import { ReportDrawerComponent } from './components/report-drawer/report-drawer.component';
import { SelectionComponent } from './components/selection/selection.component';
import { SessionTimeoutDialogComponent } from './components/session-timeout-dialog/session-timeout-dialog.component';
import { StepperWizardComponent } from './components/stepper-wizard/stepper-wizard.component';
import {
  StepperWizardBodyDirective,
  StepperWizardFooterDirective
} from './components/stepper-wizard/stepper-wizard.directive';
import { UserPreferenceDialogComponent } from './components/user-preference-dialog/user-preference-dialog.component';
import { KendoDateFormatDirective } from './directives/kendo-date-format.directive';
import { KendoDateTimeFormatDirective } from './directives/kendo-date-time-format.directive';
import { KendoTimeFormatDirective } from './directives/kendo-time-format.directive';
import { ConnectionTypePipe } from './pipes/connection-type.pipe';
import { ContextPipe } from './pipes/context.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { LocalizeDateTimePipe } from './pipes/localize-date-time.pipe';
import { LocalizeNumberPipe } from './pipes/localize-number.pipe';
import { ShowTitlePipe } from './pipes/show-title.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';

//Kendo Intl for calendar
import '@progress/kendo-angular-intl/locales/en/calendar';
import '@progress/kendo-angular-intl/locales/es/calendar';
import '@progress/kendo-angular-intl/locales/ja/calendar';
import '@progress/kendo-angular-intl/locales/ko/calendar';
import '@progress/kendo-angular-intl/locales/pl/calendar';
import '@progress/kendo-angular-intl/locales/th/calendar';
import '@progress/kendo-angular-intl/locales/zh/calendar';

const PUBLIC_DECLARATIONS = [
  ConnectionConfigurationComponent,
  ConnectionFilePickerComponent,
  AccessJobConfigurationComponent,
  ConnectionComponent,
  SelectionComponent,
  AccessTokenComponent,
  StepperWizardComponent,
  StepperWizardFooterDirective,
  StepperWizardBodyDirective,
  ContextPipe,
  TimeAgoPipe,
  JoinPipe,
  LocalizeDateTimePipe,
  LocalizeNumberPipe,
  ConnectionTypePipe,
  DataSourceSelectionComponent,
  AccessDetailComponent,
  NavCardComponent,
  ShowTitlePipe,
  DownloadJobLogsComponent,
  FilterBarItemComponent,
  FilterBarComponent,
  AuditLogsDrawerComponent,
  SessionTimeoutDialogComponent,
  EnrollmentDialogComponent,
  UserPreferenceDialogComponent,
  ReportDrawerComponent,
  KendoDateFormatDirective,
  KendoTimeFormatDirective,
  KendoDateTimeFormatDirective
];

@NgModule({
  declarations: PUBLIC_DECLARATIONS,
  exports: PUBLIC_DECLARATIONS,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ...UI_ICONS,
    TextBoxModule,
    ButtonsModule,
    UploadsModule,
    InputsModule,
    LabelModule,
    ...UI_MASTER_DETAIL,
    DropDownsModule,
    StepperModule,
    SharedCommonUiModule,
    LayoutModule,
    ElementsCommonModule,
    DialogsModule,
    GridModule,
    NotificationModule,
    DateInputsModule,
    IntlModule,
    AngularResourceAuthzModule
  ],
  providers: [DatePipe]
})
export class CommonUiModule {}
