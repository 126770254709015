import { MasterDetailHeaderIconDirective } from './lib/master-detail-header-icon.directive';
import { MasterDetailDetailPaneDirective } from './lib/master-detail-detail-pane.directive';
import { MasterDetailFooterToolbarComponent } from './lib/master-detail-footer-toolbar';
import { MasterDetailHeaderTitleComponent } from './lib/master-detail-header-title.component';
import { MasterDetailHeaderComponent } from './lib/master-detail-header.component';
import { MasterDetailSectionDirective } from './lib/master-detail-section.directive';
import { MasterDetailSectionComponent } from './lib/master-detail-section.component';
import { MasterDetailMasterPaneDirective } from './lib/master-detail-master-pane.directive';
import { MasterDetailDirective } from './lib/master-detail.directive';
import { MasterDetailHeaderToolbarComponent } from './lib/master-detail-header-toolbar';
import { MasterDetailHeaderToolbarDirective } from './lib/master-detail-header-toolbar.directive';

/**
 * Utility array that contains master-detail header components and directives
 */
export const UI_MASTER_DETAIL_HEADER = [
  MasterDetailHeaderIconDirective,
  MasterDetailHeaderTitleComponent,
  MasterDetailHeaderComponent,
  MasterDetailHeaderToolbarComponent,
  MasterDetailHeaderToolbarDirective
] as const;

/**
 * Utility array that contains master-detail footer components and directives
 */
export const UI_MASTER_DETAIL_FOOTER = [MasterDetailFooterToolbarComponent] as const;

/**
 * Utility array that contains master-detail section components and directives
 */
export const UI_MASTER_DETAIL_SECTION = [MasterDetailSectionDirective, MasterDetailSectionComponent] as const;

/**
 * Utility array that contains master-detail content related components and directives such as headers and footers, etc
 */
export const UI_MASTER_DETAIL_CONTENT = [
  ...UI_MASTER_DETAIL_FOOTER,
  ...UI_MASTER_DETAIL_HEADER,
  ...UI_MASTER_DETAIL_SECTION
] as const;

/**
 * Utility array that contains master-detail major page layout related components and directives
 */
export const UI_MASTER_DETAIL_LAYOUT = [
  MasterDetailDetailPaneDirective,
  MasterDetailDirective,
  MasterDetailMasterPaneDirective
] as const;

/**
 * Utility array that contains all `@mri-platform/ui-master-detail` related components and directives
 */
export const UI_MASTER_DETAIL = [...UI_MASTER_DETAIL_CONTENT, ...UI_MASTER_DETAIL_LAYOUT] as const;

export * from './lib/detail-page-back-btn';
export {
  MasterDetailDetailPaneDirective,
  MasterDetailDirective,
  MasterDetailMasterPaneDirective,
  MasterDetailFooterToolbarComponent,
  MasterDetailHeaderIconDirective,
  MasterDetailHeaderTitleComponent,
  MasterDetailHeaderComponent,
  MasterDetailHeaderToolbarComponent,
  MasterDetailHeaderToolbarDirective,
  MasterDetailSectionDirective,
  MasterDetailSectionComponent
};
